import React from 'react';
import { ConfigContext, ConfigState, IlSpinner, OtkIdentityService } from '@edgenuity/cns-ui';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from '../../shared/enums/routes.enum';

export function LmsLaunch() {
	const navigate = useNavigate();
	const configState = React.useContext(ConfigContext) as ConfigState;
	const identityService = new OtkIdentityService(configState.service);

	identityService
		.fetchLMSLaunchLocation()
		.then((launchLocation: string) => {
			window.location.replace(launchLocation);
		})
		.catch((err) => {
			console.error(err);
			navigate('/' + RoutesEnum.LOGIN);
		});

	return <IlSpinner />;
}
