import {
	BackgroundEnum,
	BrandContext,
	BrandState,
	ConsumerBackground,
	IlErrorBoundaryWithBrandAndNotifications,
	ILLogo,
	LogoEnum,
	RouterProps,
} from '@edgenuity/cns-ui';
import { getBrandConfigValue } from '@edgenuity/cns-ui/shared/helpers/get-config-value';
import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';

import AppRoutes from './App.routes';
import './App.css';

class App extends React.Component<PropsWithChildren<RouterProps>> {
	brandState: BrandState | undefined;

	// If we need to setup multiple contexts we can either switch
	//  to a functional component or use context consumers
	// More info on context consumers:
	//  - https://reactjs.org/docs/context.html#contextconsumer
	//  - https://stackoverflow.com/a/63432072/1863724
	static contextType = BrandContext;

	transformTitle(str: string) {
		return str.substring(0, 1) + str.substring(1).toLowerCase();
	}

	render() {
		this.brandState = this.context as BrandState;

		const brand: string = this.transformTitle(this.brandState.brand);
		const path: string = this.transformTitle(this.props.path);
		const background: BackgroundEnum = BackgroundEnum[this.brandState.brand];
		const logo: LogoEnum = LogoEnum[this.brandState.brand];

		return (
			<IlErrorBoundaryWithBrandAndNotifications>
				<div className="App">
					{/* App Head */}
					<Helmet>
						<title>
							{brand} - {path}
						</title>
						<link
							rel="icon"
							id="favicon"
							type="image/icon-x"
							href={getBrandConfigValue(this.brandState.brand)}
						/>
					</Helmet>

					{/* Header */}
					<header className="App-header">
						<ILLogo logo={logo} sx={{ width: '270px', mx: 'auto' }} />
						<h1 className="sr-only" data-testid="app-title">
							{brand} - {path}
						</h1>
					</header>

					{/* Main */}
					<main className="App-main">
						<AppRoutes />
					</main>
				</div>
				<ConsumerBackground background={background} />
			</IlErrorBoundaryWithBrandAndNotifications>
		);
	}
}

export default App;
