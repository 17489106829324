import {
	BrandProvider,
	ConfigProvider,
	withBrand,
	withConfig,
	withRouter,
} from '@edgenuity/cns-ui';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './components/app/App';
import reportWebVitals from './reportWebVitals';
import './index.css';

// create app root
const root: ReactDOM.Root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// add the hocs to the App element
const AppWithHocs: React.ElementType = withConfig(withBrand(withRouter(App)));

// add the providers to the app
const AppWithProviders = (
	<ConfigProvider>
		<BrandProvider>
			<HelmetProvider>
				<Router>
					<AppWithHocs />
				</Router>
			</HelmetProvider>
		</BrandProvider>
	</ConfigProvider>
);

// render the app with strict mode
root.render(<React.StrictMode>{AppWithProviders}</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
