import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Login from '../login/login';
import ForgotPassword from '../forgot-password/forgot-password';
import ResetPassword from '../reset-password/reset-password';
import { RoutesEnum } from '../../shared/enums/routes.enum';
import { LmsLaunch } from '../lms-launch/lms-launch';

class AppRoutes extends React.Component {
	render() {
		return (
			<Routes>
				<Route path={RoutesEnum.LOGIN} element={<Login />} />
				<Route path={RoutesEnum.FORGOT_PASSWORD} element={<ForgotPassword />} />
				<Route path={RoutesEnum.RESET_PASSWORD} element={<ResetPassword />} />
				{/* Default Route */}
				<Route path={RoutesEnum.DEFAULT} element={<Navigate to="/login" />} />

				{/* LMS Launch */}
				<Route path={RoutesEnum.LMS_LAUNCH} element={<LmsLaunch />} />
			</Routes>
		);
	}
}

export default AppRoutes;
