import React from 'react';
import {
	ApiError,
	BrandContext,
	ConfigContext,
	LoginForm,
	OtkIdentityService,
} from '@edgenuity/cns-ui';
import LoginFormValuesInterface from '@edgenuity/cns-ui/shared/interfaces/login-form-values.interface';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from '../../shared/enums/routes.enum';
import axios, { AxiosResponse } from 'axios';

export function handleLoginErrors(e: unknown): string {
	const rejectReason = (e as ApiError).rejectReason;
	if (axios.isAxiosError(rejectReason)) {
		const response = rejectReason.response as AxiosResponse;
		const lockoutDuration = response.data.lockoutDuration;
		const message = response.data.message;

		if (message?.includes('Inactive')) {
			if (message.includes('Teacher')) {
				return 'Your account has been deactivated. Please contact Tech Support for assistance.';
			} else {
				return 'Please talk to your teacher. Your login is inactive.';
			}
		}

		if (lockoutDuration && lockoutDuration > 0) {
			return `The username or password you entered is incorrect. This account has been temporarily locked. Login may be attempted again in ${lockoutDuration} seconds.`;
		}
	}
	return 'Your login or password is incorrect, please try again';
}

export default function Login() {
	const [errorState, setErrorState] = React.useState<string | undefined>();
	const configState = React.useContext(ConfigContext);
	const brandState = React.useContext(BrandContext);
	const navigate = useNavigate();

	async function onSubmit(formValues: LoginFormValuesInterface) {
		setErrorState(undefined);
		if (configState && brandState) {
			const identityService = new OtkIdentityService(configState.service);
			try {
				const response = await identityService.login(
					formValues.usernameOrEmail,
					formValues.password,
					brandState.brand
				);
				identityService.authService.store({ token: response.access_token });
				navigate('/' + RoutesEnum.LMS_LAUNCH, { state: response });
			} catch (e) {
				setErrorState(handleLoginErrors(e));
			}
		}
	}

	return (
		<LoginForm
			headingText="Welcome Back!"
			forgotPasswordLink="/forgot-password"
			signUpLink="/signup"
			errorMessage={errorState}
			onSubmit={onSubmit}
		/>
	);
}
