import React, { useState } from 'react';
import {
	BrandContext,
	BrandState,
	ConfigContext,
	ConfigState,
	ForgotPassword,
	ForgotPasswordMessage,
} from '@edgenuity/cns-ui';
import { OtkIdentityService } from '@edgenuity/cns-ui/services/otk-identity.service';
import ForgotPasswordFormValuesInterface from '@edgenuity/cns-ui/shared/interfaces/forgot-password-values.interface';

function ForgotPasswordForm() {
	const brandState = React.useContext(BrandContext) as BrandState;
	const configState = React.useContext(ConfigContext) as ConfigState;
	const [email, setEmail] = useState<string>();

	async function handleOnSubmit(formValues: ForgotPasswordFormValuesInterface) {
		if (configState) {
			const identityService = new OtkIdentityService(configState.service);
			const response = await identityService.requestPasswordResetEmail(
				formValues.email,
				brandState.brandService.brand
			);

			if (response.status === 202) {
				setEmail(formValues.email);
			}
		}
	}

	return email ? (
		<ForgotPasswordMessage email={email} />
	) : (
		<ForgotPassword onSubmit={handleOnSubmit} />
	);
}

export default ForgotPasswordForm;
