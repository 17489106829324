import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OtkIdentityService } from '@edgenuity/cns-ui/services/otk-identity.service';
import { ResetYourPassword, ConfigContext } from '@edgenuity/cns-ui';
import { ResetPasswordFormValuesInterface } from '@edgenuity/cns-ui/shared/interfaces/reset-your-password-values.interface';
import { RoutesEnum } from '../../shared/enums/routes.enum';

function ResetPassword() {
	const params = useParams();
	const configState = React.useContext(ConfigContext);
	const navigate = useNavigate();

	async function handleOnSubmit(formValues: ResetPasswordFormValuesInterface) {
		const { password } = formValues;
		if (configState) {
			const identityService = new OtkIdentityService(configState.service);
			const response = await identityService.resetPasswordWithToken(password, params.token || '');

			if (response.status === 204) {
				navigate(RoutesEnum.LOGIN);
			}
		}
	}

	return <ResetYourPassword onSubmit={handleOnSubmit} />;
}

export default ResetPassword;
